/**
/ p-tabview START
**/
// .p-tabview .p-tabview-nav-content {
//   scroll-padding-inline: 3rem;
// }
.p-tabview-nav-container{

  font-size: 1rem;
  font-weight: 500;
}
.p-tabview .p-tabview-nav {
  background: transparent;
  border: solid #D9D9D9;
  border-width: 0 0 1px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-title{
  line-height: 1.313rem;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid rgba(255, 255, 255, 0.12);
  border-width: 0 0 1px 0;
  border-color: transparent transparent rgba(255, 255, 255, 0.12) transparent;
  background: transparent;
  color: #848488;
  padding: 1rem 1rem;
  padding-top: 0.875rem;
  padding-bottom: calc(0.875rem - 3px);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: none;
  margin: 0 0 0 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset none;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  color: #011E18;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.12);
  color: #011E18;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
// .p-tabview .p-tabview-nav-btn.p-link {
//   background: transparent;
//   color: #9FA8DA;
//   width: 3rem;
//   box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
//   border-radius: 0;
// }
.p-tabview .p-tabview-nav-btn.p-link:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset none;
}
.p-tabview .p-tabview-panels {
  border: 0 none;
}

.p-tabview .p-tabview-panel{
  margin-top: 1.25rem;
}

.p-tabview .p-tabview-nav {
  position: relative;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: background-color 0.2s;
  border-radius: 0;
}
// .p-tabview .p-tabview-nav li .p-tabview-nav-link > .p-ink {
//   background-color: rgba(159, 168, 218, 0.16);
// }
// .p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
//   background-color: rgba(159, 168, 218, 0.12);
// }
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #011E18;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-tabview .p-tabview-nav-btn.p-link {
  color: #64748b;
  background-color: #ebeeef;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 10px 25px #ebeeef;
}
/**
/ p-tabview END
**/

/**
/ table
**/
.p-datatable{

  .p-datatable-header{
    font-size: 0.875rem;
    line-height: 1rem;
    background-color: #FFFFFFbf;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-bottom: 1px solid #EBEEEF;
  }

  .p-datatable-loading-overlay{
    background-color: #FFFFFFbf;
  }

  .p-column-resizer-helper{
    border-left: 1px solid #DAFF00;
  }

  &.p-frozen-column{
    background-color: #FFFFFF;

  }

  .p-datatable-thead{
    background-color: #FFFFFF;
    line-height: 1rem;
    font-weight: 500;
    font-size: 0.875rem;


    th{
      padding-top:0.4rem;
      padding-bottom:0.4rem;
      text-align: left;
      // border: 1px solid #EBEEEF;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      font-weight: 500;
      background-color: #FFFFFF;
      border-bottom: 1px solid #EBEEEF;

      // &.p-frozen-column:first-child{
      //   box-shadow: inset -1px 0 0px #e4e4e4;
      // }

      // &.p-frozen-column:last-child{
      //   box-shadow: inset 1px 0 0px #e4e4e4;
      // }

      .p-column-resizer{
        width: .5rem
      }

      .p-column-resizer:hover{
        background-color: #e4e4e4;
      }

      .p-sortable-column-icon{
        margin-left: 0.5rem;
      }

    }


  }

  .p-datatable-tbody{
    background-color: #FFFFFF;

    tr{
      background-color: #FFFFFF;
      height: 2.5rem;
      

      &:hover {
        background-color: #f3f4f6 !important  ;
      }

      &.clickable{
        cursor: pointer;
      }

      .p-checkbox{
        vertical-align: middle;
        margin-top: -0.188rem;
      }

      &.p-highlight{
        background: #EAEEEF;
      }
    }

    td{
      border-top: 1px solid #e4e4e4;
      // border-bottom: 1px solid #e4e4e4;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 0;
      padding-bottom: 0;
      white-space: nowrap;
      font-size: 0.875rem;
      color: #848488;
      text-overflow: ellipsis;


      &.iconTable{
        padding-top: 0rem;
        padding-bottom: 0rem;
        padding-left:1rem;
      }

      // &.p-frozen-column:first-child{
      //   box-shadow: inset -1px 0 0px #e4e4e4;
      // }

      // &.p-frozen-column:last-child{
      //   box-shadow: inset 1px 0 0px #e4e4e4;
      // }

      &.emptyMessage {
        color: #848488;
        font-weight: 300;;
      }
    }

    .icon {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
}

// .p-datatable-flex-scrollable{

//   .p-paginator{
//     border-top: 1px solid #e4e4e4;
//   }

// }

.p-datatable-wrapper {
  -ms-overflow-style: none;  // IE und Edge
  scrollbar-width: none;     // Firefox
  background-color: #FFFFFF;
  &::-webkit-scrollbar {
    display: none;          // Chrome, Safari und Opera
  }
}

/**
/ tag
**/

.p-tag {
  background-color: #daff00;
  border: 1px solid #9bb30b;
  color: #637400;
  line-height: 1rem;
  border-radius: 4px;
  padding: 1px 6px;
  font-size: 0.75rem; //base
  line-height: 1rem;  //base

  .p-tag-value{
    line-height: 1rem;
  }

  &.lg{
    font-size: 1rem;
    .p-tag-value{
      line-height: 1.3rem;
    }
  }

  &.xl{
    font-size: 1.2rem;
    .p-tag-value{
      line-height: 1.6rem;
    }
  }

}

.p-tag.p-tag-info {
  background-color: #ebeef1;
  border: 1px solid #d8dee4;
  color: #596171;
}

.p-tag.p-tag-warning, .p-tag.p-tag-danger {
  border: 1px solid #fbd3de;
  background-color: #fde9ee;
  color: #c0123c;
}

.p-tag.p-tag-warning {
  border: 1px solid #a3f0f3;
  background-color: #cff5f6;
  color: #0055bc;
}

.p-tag.p-tag-success{
  border: 1px solid #a8f170;
  background-color: #d1fab3;
  color: #217005;
}


// .p-tag .p-tag-icon {
//   margin-right: 0.25rem;
//   font-size: 0.75rem;
// }
// .p-tag .p-icon {
//   width: 0.75rem;
//   height: 0.75rem;
// }

.p-icon {
  display: inline-block;
}

.p-multiselect{
  border: 0.063rem solid #e4e4e4;
  border-radius: 0.25rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  color: #001E18;
  font-weight: 400;

  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.337);
  }

}

.location-selector{
  border: 0;
  background-color: #EAEEEF;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: #001E18;
  font-weight: 400;
  padding: 0.1875rem;
  line-height: 1.375rem;
  min-width:200px;

  &.dark{
    background-color: #EAEEEF;
  }

  &.light{
    background-color: white;
  }

  .p-multiselect-label{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .placeholder{
    color: #888484;
  }
}

.location-selector-single{

  // border-radius: 0.25rem;
  // font-size: 0.875rem;
  // color: #001E18;
  // font-weight: 400;
  // padding: 0.1875rem;
  // line-height: 1.375rem;
  min-width:180px;


  &.p-dropdown{

    border:0;

    &.dark{
      background-color: #EAEEEF;
    }

    &.light{
      background-color: white;
    }

    .p-inputtext{
      padding: 4px;
      padding-right:10px;

      &.p-dropdown-label:not(.p-placeholder){
        color: #001E18;
      }
    }

    .p-dropdown-trigger{
      padding-right: 5px;
    }
  }


}

.table-filter {

  display: inline-block;

  .p-multiselect{
    display: flex;
    align-items: center;
    padding: 4px 6px;
    border: 1px dashed #d3d3d3; /* Dashed border */
    border-radius: 20px; /* Rounded corners */
    cursor: pointer;

    &.p-inputwrapper-filled{

      border: 1px solid #d3d3d3;

      .p-multiselect-trigger-icon{
        display: none;
      }
    }

    .p-multiselect-label-container{
      order:3;
      display: flex;
      flex-direction: row-reverse;
      line-height: 1rem;
    }

    .p-multiselect-trigger{
      order: 2;
    }

    .p-multiselect-clear{
      order: 1;
      width:20px;
    }

    .p-multiselect-trigger-icon, .p-multiselect-clear-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eef1f2; /* Light background for the icon */
      border-radius: 50%; /* Circle shape */
      width: 18px;
      height: 18px;
      font-size: 12px; /* Icon size */
      color: #344; /* Icon color */
      margin-right: 5px;
    }

    .p-multiselect-clear-icon{
      background-color: #DAFF00;
      transform: rotate(45deg);
    }
  }

  .p-dropdown{
    display: flex;
    align-items: center;
    padding: 4px 6px;
    border: 1px dashed #d3d3d3; /* Dashed border */
    border-radius: 20px; /* Rounded corners */
    cursor: pointer;

    &.p-inputwrapper-filled{

      border: 1px solid #d3d3d3;

      .p-dropdown-trigger-icon{
        display: none;
      }
    }

    .p-dropdown-label{
      order:3;
      display: flex;
      flex-direction: row-reverse;
    }

    .p-dropdown-trigger{
      order: 2;
    }

    .p-dropdown-clear{
      order: 1;
      display: flex;
    }

    .p-dropdown-trigger-icon, .p-dropdown-clear-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eef1f2; /* Light background for the icon */
      border-radius: 50%; /* Circle shape */
      width: 18px;
      height: 18px;
      font-size: 12px; /* Icon size */
      color: #344; /* Icon color */
      margin-right: 5px;
    }

    .p-dropdown-clear-icon{
      background-color: #DAFF00;
    }

    .p-inputtext{
      padding:0;
      height: 16px;
      border:0;
    }
  }

}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel{
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #d8dee4;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 0.875rem;
  width: 240px;
  max-width: 240px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding: 0.1875rem;

  .p-multiselect-items .p-multiselect-item {
    padding: 0.313rem 0.625rem;
    border-radius: 4px;


    &:hover {
      background: #f3f4f6;
    }

    &.p-highlight {
      background: #DAFF00;


      :hover {
        background: #DAFF00;
      }
    }
  }

  .p-multiselect-items > .p-element:not(:last-child) > li{
    margin-bottom: 2px;
  }

  .p-multiselect-items-wrapper{
    overflow: auto;
  }
}

.p-column-filter{

  .p-inputtext {
    color: #495057;
    background: #ffffff;
    padding: 1rem 0.625rem;
    border: 0.063rem solid #e4e4e4;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 3px;

    &::placeholder{
      color: #888484;
    }
  }


  .p-inputtext:enabled:hover {
    border-color: rgba(0, 0, 0, 0.337);
  }

  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    // box-shadow: 0 0 0 0.2rem #BFDBFE;
    border-color: rgba(0, 0, 0, 0.337);
  }

  .p-column-filter-menu-button{
    padding: 0.5rem;
    border-radius: 4px;

  }

  .p-column-filter-menu-button-active {
    background-color: #DAFF00;
  }

  .p-column-filter-clear-button .p-icon{
    margin-left: 0.5rem;
  }
}

.p-column-filter-overlay {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-width: 10rem;
  padding: 0.75rem;
}

.p-column-filter-buttonbar {
  margin-top: 0.5rem;
  justify-content: end;

  .p-button{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 4px;

    &:hover {
      background: #DAFF00;
    }
  }
}

.p-checkbox-box{
  border: 0.063rem solid #e4e4e4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;

  .inline-block{
    padding-top:1px;
  }
}

.p-checkbox .p-checkbox-box {
  border: 1px solid #e4e4e4;
  background: #ffffff;
  width: 16px;
  height: 16px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;

  &:enabled:focus, &:hover {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.337);
  }
}

.p-inputtext {
  border: 0;
  border-radius: 0.25rem;
  padding: 0.5rem;

  height:1.75rem;
  font-size: 0.875rem;
  color: #848488;
  font-weight: 400;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);

  &:enabled:focus, &:hover {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.337);
  }

  &.p-inputtextarea-resizable{
    resize: none;
  }

  &.invers{
    background-color: #EAEEEF;
  }

  &.right{
    text-align: right;
  }


}

.p-input-icon-left{
  .p-inputtext {
    padding-left: 2rem;
  }
}

.p-input-icon-left > i,
.p-input-icon-left > .p-icon-wrapper,
.p-input-icon-right > i,
.p-input-icon-right > .p-icon-wrapper {
    position: absolute;
    top: 50%;
    margin-top: -0.938rem;
    font-size: 2rem;
}

.p-input-icon-left{
  .p-inputtext {
    padding-left: 2rem;
  }
}


.p-paginator {
  background: #ffffff;
  border: solid #e9ecef;
  border-width: 0;
  height: 2.5rem;
  line-height: 1rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: wrap;
  border-top: 1px solid #e4e4e4;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  .p-paginator-current{
    margin-right: 2em;
  }

  button{
    padding: 0;
  }

  .p-highlight{
    background: #EAEEEF;

    border-radius: 4px;
  }

  .p-paginator-rpp-options{
    margin-left:2em;
    border-radius: 0.25rem;
    padding: 0.25rem;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);

    &:enabled:focus, &:hover {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: rgba(0, 0, 0, 0.337);
    }
  }

  .p-dropdown-label {
    background: transparent;
    border: 0 none;
    padding: 0;
    padding-right:1em;
  }

  button{
    padding: 2px 4px;
    margin-left: 2px;
    margin-right: 2px;
    &.p-disabled{
      color: #e4e4e4;
    }
  }

  .p-inputtext{
    height: 1rem;
  }
}

.p-dropdown{
  border: 0.063rem solid #e4e4e4;
  border-radius: 0.25rem;

  .p-dropdown-label:focus, .p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }

  .placeholder{
    color: #888484;
  }

  &.sort{
    background-color: #EAEEEF;
    border: 0;
    border-radius: 20px;
    padding: 6px 10px;
    line-height: 16px;

    .p-inputtext{
      border: 0;
      padding: 0;
      margin-right:4px;
      color: #001E18;
      height: 1rem;
    }

    .p-placeholder{
      color: #888484 !important;
    }
  }
}

.p-dropdown-panel {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #d8dee4;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 0.875rem;
  width: 240px;
  max-width: 240px;
  padding-top: 4px;
  padding-bottom: 4px;

  .p-dropdown-item {
    padding: 0.313rem 0.625rem;
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;

    &:hover {
      background: #f3f4f6;
    }

    &.p-highlight {
      background: #DAFF00;


      :hover {
        background: #DAFF00;
      }
    }
  }

  .p-dropdown-items > .p-element:not(:last-child) > li{
    margin-bottom: 2px;
  }
}

.p-menu{
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #d8dee4;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 14px;
  width: 240px;
  max-width: 240px;
  padding-top: 4px;
  padding-bottom: 4px;

  .p-menuitem{
    padding: 0.313rem 0.625rem;
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;

    a{
      display: block;
    }

    &:hover {
      background: #f3f4f6;
    }
  }

  .p-menuitem-separator{
    border-top: 1px solid #e4e4e4;
    margin-top: 0.313rem;
    margin-bottom: 0.313rem;
  }

  .p-menuitem-content{
    cursor: pointer;
  }

}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color:  rgba(0, 0, 0, 0.4);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color:  rgba(0, 0, 0, 0.4);
  }
  to {
    background-color: transparent;
  }
}

.p-dialog .p-dialog-content {
  background: #ffffff;
}

p-button{
  border-radius: 5px;
}

/**
/ this is a hard fix for a problem between tailwind and ng prime. in the base
/ tailwind css file there are *, :before, :after rule that overwrite the
/ default values of ngprime. we overwrite tailwind here again with the code
/ from the default ngprime file
**/
.p-tooltip .p-tooltip-text {
  background: #EBEEEF;
  color: #000000;
  padding: 0.2rem 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 0.75rem;
}

.p-tooltip-left .p-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -0.25rem;
  border-width: 0.25em 0 0.25em 0.25rem !important;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #EBEEEF !important;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #EBEEEF !important;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #EBEEEF !important;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #EBEEEF !important;
}

.emptyMessage {
  height: 2.5rem;
  background-color: white;
  color: #C1C1C1;
  text-align:center;
}
.collapseContainer {
  max-width: 1380px;
}

.p-inputswitch {
  width: 2.75rem;
  height: 1rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: #777777;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 0.5rem;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.5rem;
  height: 1.5rem;
  left: -1px;
  margin-top: -0.75rem;
  border-radius: 50%;
  transition-duration: 0.2s;
  box-shadow: 0px 1px 2px #929292;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.5rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  // background: #777777;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #DBDBDB;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #DAFF00;

}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  // background: #777777;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #B00020;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.38;
}

.nx-default-button {

  color:#000000de;

  button{
    line-height: 1.25rem;
    overflow: visible;
  }

  .transparent {

    .p-button{
      background: transparent;
      border: 1px solid transparent;

      &:hover {
        background: #dddddd;
        border: 1px solid #dddddd;
      }
    }

  }

  .light{
    .p-button{
      background: #ffffff;
      border: 1px solid #ffffff;

      &:hover {
        background: #dddddd;
        border: 1px solid #dddddd;
      }
    }
  }

  .dark {
    .p-button{
      background: #EAEEEF;
      border: 1px solid #EAEEEF;

      &:hover {
        background: #dddddd;
        border: 1px solid #dddddd;
      }
    }
  }

  .p-button {
    font-size: 0.875rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0.313rem;
  }

  .p-button:enabled:active {
    background: #d1f500;
    border-color: #d1f500;
  }

  .p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0;
  }

  i {
    font-size: 1.563rem;
    position: relative;
    top: 0.062rem;
    left: -0.031rem;
  }

  .p-button-label{
    //padding-right: 0.313rem;
    padding-top: 0.188rem;
    padding-bottom: 0.188rem;
  }

  .icon-right{
    .p-button-label{
      padding-left: 0.313rem;
    }
  }

  .p-button.p-button-text-only {
    padding: 0 0.625rem;
  }

  .p-button.p-button-text-and-icon {

    .p-button-label{
      padding-right: 0.313rem;
      white-space: nowrap;  
    }
  }

  .p-button.p-button-icon-only {
    padding: 0 0;
  }

  .p-button.p-button-icon-only .p-button-icon-left,
  .p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
  }

  .p-button-full-width {
    width: 100%;
  }
}

.p-overlaypanel {
  background: #ffffff;
  color: #495057;
  margin:0;
  border: 1px solid #d8dee4;
  border-radius: 6px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 0.625rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #3B82F6;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #2563eb;
  color: #ffffff;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  display: none;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #f2f2f2;
  display: none;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #ffffff;
}

.form{

  > *:not(:last-child) {
    margin-bottom: 0.75rem; /* Beispielstil für alle direkten Kind-Elemente */
  }

  .p-inputtext {
    width: 100%;
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    color: #495057;
    background: #ffffff;
    padding: 1rem 0.625rem;
    border: 0.063rem solid #e4e4e4;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 3px;

    &::placeholder{
      color: #888484;
    }
  }


  .p-inputtext:enabled:hover {
    border-color: rgba(0, 0, 0, 0.337);
  }

  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    // box-shadow: 0 0 0 0.2rem #BFDBFE;
    border-color: rgba(0, 0, 0, 0.337);
  }

  label, small{
    font-size: 0.75rem;
    margin-left: 0.625rem;
  }

  small{
    color: #b0b0b0;

    &.error{
      color: #FF1500;
    }
  }

  .p-multiselect{
    width:100%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

    .p-placeholder{
      color: #888484;
    }
  }

  .p-multiselect:hover {
    border-color: rgba(0, 0, 0, 0.337);
  }

  .ng-invalid.ng-touched {

    .p-inputtext, .p-dropdown {
      border-color: #FF1500;
    }

  }

  nx-checkbox-text-input{
    margin-top: 4px;
    margin-bottom: 14px;

    // p-checkbox{
    //   width: 100%
    // }
  }
}

.nx-edit-selectCountry, .nx-edit-select, .nx-edit-dateTime{

  .p-dropdown{
    width: 100%;
    height: 34px;

    &:hover {
      border-color: rgba(0, 0, 0, 0.337);
    }

    .p-inputtext {
      border: 0;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 6px;
    }

    .p-placeholder {
      color: #888484;
    }

    .p-dropdown-trigger{
      padding-right: 10px;
    }
  }

  .p-dropdown-filter-icon{
    display:none;
  }

  // .p-dropdown-filter-container{
  //   margin: 5px;
  //   background: grey;
  //   width: 98%;
  // }

  .p-dropdown-header {
    padding: 0.75rem 1.25rem;
    color: #495057;
    background: #EAEEEF;
    margin: 0;

    .p-inputtext{
      color: #495057;
      border: 1px solid #EAEEEF;

      &::placeholder{
        color: #888484;
      }

      &:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        // box-shadow: 0 0 0 0.2rem #BFDBFE;
        border: 1px solid rgba(0, 0, 0, 0.337);
      }
    }
  }

  .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.75rem;
    margin-right: -1.75rem;
  }


}


.nx-dialog {

  .p-dialog{

    box-shadow: 0px 3px 6px #00000029;
    background-color: #ffffff;
    border-radius: 5px;

    .p-dialog-header{
      background-color: #DAFF00;
      font-size: 1rem;
      padding: 0.75rem 0.625rem;
      border-top-right-radius: 0.313rem;
      border-top-left-radius: 0.313rem;
      font-weight: 500;
    }

    .p-dialog-content{
      margin: 1.25rem 0.625rem;
    }

    .p-dialog-footer{
      border-bottom-right-radius: 0.313rem;
      border-bottom-left-radius: 0.313rem;
    }
  }

  .p-dialog-footer{
    background-color: white;;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  nx-default-button.reject {

    .p-button{
      border: 1px solid #EAEEEF;
    }

  }

  nx-default-button {

    &.confirm{

      .p-button{
        background: #DAFF00;
        border-color: #DAFF00;
      }
    }

    &.confirm.warn{

      .p-button{
        background: #FF1500;
        border-color: #FF1500;
        color: white;
      }
    }
  }

}

.nx-edit-dialog{

  .close{
    transform: rotate(45deg);
    position: static;
    margin-top: 0;
    font-size: 22px;
    line-height: 10px;
    cursor: pointer;
  }

  .p-multiselect-header{
    display: none;
  }
}

// .nx-default-button {
//   .nx-toast-action{
//     .p-button{
//       background: #DAFF00;
//       color: #000000
//     }
//   }
// }

nx-toast{
  .nx-default-button .transparent .p-button {
    // background: white;
    border: 1px solid transparent;

    &:hover {
      background: #4a4949;
      border: 1px solid #4a4949;
    }
  }
}

.p-datepicker {
  padding: 0.75rem;
  background: #ffffff;
  color: #333333;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
  }

  .p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #333333;
  background: #ffffff;
  font-weight: 500;
  margin: 0;
  border-bottom: 1px solid #f0f0f0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev,
  .p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 1.75rem;
  height: 1.75rem;
  color: #666666;
  border: 0 none;
  background: transparent;
  border-radius: 4px;
  transition: background-color 0.2s;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #333333;
  background: #f3f4f6;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
  .p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
  outline: 0 none;
  box-shadow: 0 0 0 2px rgba(218, 255, 0, 0.2);
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 1.75rem;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #333333;
  transition: background-color 0.2s;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: #000000;
  background: #f3f4f6;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.25rem;
  }

  .p-datepicker table {
  font-size: 0.875rem;
  margin: 0.25rem 0;
  }

  .p-datepicker table th {
  padding: 0.25rem;
  color: #666666;
  font-weight: 500;
  }

  .p-datepicker table th > span {
  width: 2rem;
  height: 2rem;
  }

  .p-datepicker table td {
  padding: 0.125rem;
  }

  .p-datepicker table td > span {
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  transition: background-color 0.2s;
  border: none;
  }

  .p-datepicker table td > span.p-highlight {
  color: #000000;
  background: #daff00;
  font-weight: 500;
  }

  .p-datepicker table td > span:focus {
  outline: 0 none;
  box-shadow: 0 0 0 2px rgba(218, 255, 0, 0.2);
  }

  .p-datepicker table td.p-datepicker-today > span {
  background: rgba(218, 255, 0, 0.15);
  color: #333333;
  font-weight: 500;
  }

  .p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #000000;
  background: #daff00;
  }

  .p-datepicker .p-datepicker-buttonbar {
  padding: 0.75rem;
  border-top: 1px solid #f0f0f0;
  }

  .p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
  background: #f5f5f5;
  color: #333333;
  border: none;
  border-radius: 4px;
  transition: background-color 0.2s;
  }

  .p-datepicker .p-datepicker-buttonbar .p-button:hover {
  background: #f3f4f6;
  }

  .p-datepicker .p-timepicker {
  border-top: 1px solid #f0f0f0;
  padding: 0.5rem;
  }

  .p-datepicker .p-timepicker button {
  width: 1.75rem;
  height: 1.75rem;
  color: #666666;
  border: 0 none;
  background: transparent;
  border-radius: 4px;
  transition: background-color 0.2s;
  }

  .p-datepicker .p-timepicker button:enabled:hover {
  color: #333333;
  background: #f3f4f6;
  }

  .p-datepicker .p-timepicker button:focus-visible {
  outline: 0 none;
  box-shadow: 0 0 0 2px rgba(218, 255, 0, 0.2);
  }

  .p-datepicker .p-timepicker span {
  font-size: 1rem;
  color: #333333;
  }

  .p-datepicker .p-timepicker > div {
  padding: 0 0.25rem;
  }

  .p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
  }

  .p-datepicker .p-monthpicker {
  margin: 0.25rem 0;
  }

  .p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.25rem;
  transition: background-color 0.2s;
  border-radius: 4px;
  }

  .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #000000;
  background: #daff00;
  }

  .p-datepicker .p-yearpicker {
  margin: 0.25rem 0;
  }

  .p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.25rem;
  transition: background-color 0.2s;
  border-radius: 4px;
  }

  .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: #000000;
  background: #daff00;
  }

  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #f0f0f0;
  padding: 0 0.5rem;
  }

  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
  border-left: 0 none;
  }

  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  }

  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #f3f4f6;
  }

  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  box-shadow: 0 0 0 2px rgba(218, 255, 0, 0.2);
  }

  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: #f3f4f6;
  }

  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  box-shadow: 0 0 0 2px rgba(218, 255, 0, 0.2);
  }

  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: #f3f4f6;
  }

  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  box-shadow: 0 0 0 2px rgba(218, 255, 0, 0.2);
  }

.cdk-drag-preview {
  z-index: 1005 !important; /* Higher z-index when dragging */
}

.cdk-drag-placeholder {
  opacity: 0.3;
}

.draggableTask{
  display: block;
  width:500px;
  background-color: #f9f9f9;
}

nx-filter-numberselect{
  font-size: 0.875rem;

  .placeholder, .suffix, .value{
    line-height:1rem;
  }
}

.p-overlay.nx-filter-numberselect{
  background: #ffffff;
  color: #495057;
  margin: 0;
  border: 1px solid #d8dee4;
  border-radius: 6px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);

  .content{
    padding: 0.625rem;
  }
}


.licenceView{
  .nx-default-button {

    &.confirm{

      .p-button{
        background: #DAFF00;
      }
    }

    &.reject{

      .p-button{
        border: 1px solid #EAEEEF;
      }
    }
  }
}

.general-settings{

  .text{
    padding-left:10px;

    &:active, &:focus{
      border: 1px solid rgba(0, 0, 0, 0.337);
    }

    &:not(:disabled){
      border: 1px solid rgba(0, 0, 0, 0.337);
    }

    &:enabled:focus, &:hover {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: rgba(0, 0, 0, 0.337);
    }
  }

  nx-default-button.reject {

    .p-button{
      border: 1px solid #EAEEEF;
    }

  }

  nx-default-button {

    &.confirm{

      .p-button{
        background: #DAFF00;
        border-color: #DAFF00;
      }
    }

  }

}

.p-splitter-gutter-handle{
  background-color: #d4d4d4;
  border: 1px solid #d4d4d4;
  border-radius: 0.375rem;

  &:hover{
    background-color: #b7b5b5;
  }
}

.p-autocomplete-panel {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding: 5px;
  margin-left: 10px;
}

.p-autocomplete-item {
  padding: 5px;
}



.mat-drawer{
  background-color: #F9F9F9 !important;;
}
.mat-drawer-side {
  border-right-width:0 !important;
}

.mat-drawer-inner-container{
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  scrollbar-width: none;     /* For Firefox */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and WebKit browsers */
  }
}

// .dropIndicator{
//   background-color: #DAFF00;
//   border: 2px solid #DAFF00;
//   position: absolute;
//   width: 100%;
//   top: calc(-0.5 * (2px + 1px));
// }

.dragActive{
  opacity: 0.3;
}

.dropActive .p-panel-header, .dropActive .empty-box{
  opacity: 0.7;
}

.dragPreview {
  background: white;
}

.p-colorpicker-preview, .p-fluid .p-colorpicker-preview.p-inputtext {
  width: 1rem;
  height: 1rem;
}

.clear{
  display: inline;
  background-color: #DAFF00;
  transform: rotate(45deg);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nx-edit-select .p-dropdown-clear-icon{
  transform: rotate(0deg);
  right: 2rem;
  position: absolute;
  top: 50%;
  margin-top: -.5rem;
}

.p-calendar-clear-icon{
  right: 1rem;
  position: absolute;
  top: 50%;
  margin-top: -.5rem;
}